"use strict";
const angular = require("angular");
const _ = require("underscore");
const moment = require("moment");

module.exports = [
    "residents.service",
    "notification.service",
    "modalConfig",
    "$state",
    "dialog.service",
    "organisation.facility.service",
    "DefaultTime",
    function (
        residentsService,
        notification,
        modalConfig,
        $state,
        dialogService,
        orgFacilityService,
        DefaultTime
    ) {
        var vm = this;
        angular.extend(vm, modalConfig);
        var _facilityService = orgFacilityService;
        vm.departure = {};
        vm.otherFacilities = [];

        if (vm.fromILU) {
            if (!vm.anotherResident) {
                vm.isVacating = true;
            }

            if (!vm.isVacating) {
                residentsService.getIfResidentHasOpenOptionalServices(vm.resident.id).then(
                    function (result) {
                        vm.hasOpenOptionalServices = result;
                        if (!vm.anotherResident.billPayerType) {
                            vm.warningOfMissingBillPayer = "Please select a Bill Payer for " + vm.anotherResident.firstName + " to pay for the unit costs";
                        }
                        if (!vm.hasOpenOptionalServices && vm.anotherResident.billPayerType) {
                            if (vm.isResponsibleResident) {
                                vm.indicationOfTransferResponsibility = "Responsibility for unit costs will be transferred to " + vm.anotherResident.firstName;
                            }
                        }
                    });
            }
            else {
                vm.vacatingMessage = "The unit will become available after the vacate date";
                residentsService.getIfResidentHasOpenOptionalServices(vm.resident.id).then(
                    function (result) {
                        vm.hasOpenOptionalServices = result;
                        if (vm.anotherResident) {
                            if (vm.isResponsibleResident) {  //current resident is responsible resident
                                residentsService.getIfResidentHasOpenOptionalServices(vm.anotherResident.id).then(
                                    function (result) {
                                        vm.hasOpenOptionalServicesForAnotherResident = result; //if another resident (non-responsible) has optional services, disable vacating.             
                                        if (vm.hasOpenOptionalServicesForAnotherResident) {
                                            vm.vacatingMessage = "";
                                        }
                                    });
                            }
                            else {  //current resident is non-responsible resident
                                if (vm.hasOpenOptionalServices) { //will disable vacating if current resident (non-responsible resident) has optional services
                                    vm.vacatingMessage = "";
                                }
                                else { //current resident doesn't have any optional services, then only give warning message if another resident (Responsible) has optional services
                                    residentsService.getIfResidentHasOpenOptionalServices(vm.anotherResident.id).then(
                                        function (result) {
                                            vm.hasOpenOptionalServicesForAnotherResident = result;
                                        });
                                }
                            }
                        }
                    });
            }
        }

        Object.defineProperty(vm, "minimumDepartureDateUtc", {
            get: function () {
                var value = null;
                if (vm.resident.admissionDateUtc) {
                    value = moment(vm.resident.admissionDateUtc)
                        .startOf("day")
                        .toDate();
                }
                return value;
            }
        });

        if (vm.isCorrection) {
            if (vm.fromILU) {
                residentsService.getIluResidentDepartureInformation(vm.residencyId).then(
                    function (departureInfo) {
                        vm.departure.departureDateUtc = moment(
                            departureInfo.departureDateUtc
                        );
                        vm.departure.departureTimeLocal = departureInfo.departureTimeLocal;
                        if (!vm.departure.departureTimeLocal) {
                            vm.departure.departureTimeLocal = DefaultTime;
                        }
                        vm.resident.admissionDateUtc = moment(departureInfo.admissionDateUtc);
                        vm.departure.residencyId = departureInfo.residencyId;
                        vm.departure.departureReasonNotes = departureInfo.departureReasonNotes;
                        vm.departure.toFacilityId = departureInfo.toFacilityId;
                        vm.departure.destinationFacilityInfo = departureInfo.destinationFacilityInfo;
                        vm.departure.latestResidencyInfo = departureInfo.latestResidencyInfo;
                        extractDepartureInformation(departureInfo.departureReason);
                    },
                    function () {
                        vm.$hide();
                        notification.error(
                            "Unable to display departure details. Either your computer is offline, or Resident Manager is having problems of its own."
                        );
                    }
                );
            }
            else {
                residentsService.getResidentDepartureInformation(vm.resident.id).then(
                    function (departureInfo) {
                        vm.departure.departureDateUtc = moment(
                            departureInfo.departureDateUtc
                        );
                        vm.departure.departureTimeLocal = departureInfo.departureTimeLocal;
                        if (!vm.departure.departureTimeLocal) {
                            vm.departure.departureTimeLocal = DefaultTime;
                        }
                        vm.resident.admissionDateUtc = moment(departureInfo.admissionDateUtc);
                        vm.departure.departureReasonNotes = departureInfo.departureReasonNotes;
                        vm.departure.toFacilityId = departureInfo.toFacilityId;
                        extractDepartureInformation(departureInfo.departureReason);
                    },
                    function () {
                        vm.$hide();
                        notification.error(
                            "Unable to display departure details. Either your computer is offline, or Resident Manager is having problems of its own."
                        );
                    }
                );
            }

        }

        vm.transferReasonSelected = function () {
            orgFacilityService.getAllOrganisationFacilities(true).then(
                function (response) {
                    vm.otherFacilities = response;
                }
            );
        };

        vm.bindHide = function ($hide) {
            vm.$hide = $hide;
        };

        vm.depart = function (isDepartureFormValid) {
            vm.departure.hasAdmissionAfterDeparture = vm.hasAdmissionAfterDeparture;
            if (isDepartureFormValid) {
                mapDepartureInformation();

                if (vm.isCorrection) {
                    if (vm.fromILU) {
                        // Still transferring but destination facility changed after they were admitted already in the previous destination facility.
                        if (vm.departure.toFacilityId != vm.departure.destinationFacilityInfo.facilityId && vm.departure.destinationFacilityInfo.admittedInDestinationFacility) {
                            dialogService.openMessageDialog(
                                "Correct departure",
                                "Departure cannot be corrected.<br/>" +
                                "<span class='error-details'>Facility must be " + vm.departure.destinationFacilityInfo.facilityName + " because resident has been admitted.</span>",
                                "departure-date-error",
                                null);
                            extractDepartureInformation(vm.departure.departureReason);
                            return;
                        }

                        // Resident admitted elsewhere, Departure date changed but is set to date before the respite leave return date. 
                        var updatedDepartureDate = moment(vm.departure.departureDateUtc);
                        var respiteLeaveEndDate = (vm.leaveEndDate != null) ? moment(vm.leaveEndDate) : null;

                        if (respiteLeaveEndDate && updatedDepartureDate.isBefore(respiteLeaveEndDate)) {
                            dialogService.openMessageDialog(
                                "Correct departure",
                                "Departure cannot be corrected.<br/>" +
                                "<span class='error-details'>Departure date must be after or equal to the resident's respite leave return date.</span>",
                                "departure-date-error",
                                null);
                            extractDepartureInformation(vm.departure.departureReason);
                            return;
                        }

                        // Resident admitted elsewhere, Departure date changed but is set to date after latest admission date in case latest event is not the departure. 
                        var latestOtherActiveResidencyAdmissionDateUtc = (vm.departure.latestResidencyInfo.admissionDateUtc != null) ? moment(vm.departure.latestResidencyInfo.admissionDateUtc) : null;

                        if (vm.hasAdmissionAfterDeparture && latestOtherActiveResidencyAdmissionDateUtc != null && updatedDepartureDate.isAfter(latestOtherActiveResidencyAdmissionDateUtc)) {
                            dialogService.openMessageDialog(
                                "Correct departure",
                                "Departure cannot be corrected.<br/>" +
                                "<span class='error-details'>Departure date must be before or equal to the resident's admission date in " + vm.departure.latestResidencyInfo.facilityName + ".</span>",
                                "departure-date-error",
                                null);
                            extractDepartureInformation(vm.departure.departureReason);
                            return;
                        }
                        residentsService
                            .correctIluResidentDeparture(
                                vm.departure
                            )
                            .then(
                                function () {
                                    handleSuccess();
                                },
                                function (error) {
                                    handleFailure(error);
                                    extractDepartureInformation(vm.departure.departureReason);
                                }
                            );
                    }
                    else {
                        residentsService
                            .correctResidentDeparture(
                                vm.resident.id,
                                vm.uniqueEventId,
                                vm.departure
                            )
                            .then(
                                function () {
                                    handleSuccess();
                                },
                                function (error) {
                                    handleFailure(error);
                                }
                            );
                    }
                }
                else {
                    residentsService.departResident(vm.resident.id, vm.departure, vm.fromILU, vm.isVacating).then(
                        function () {
                            handleSuccess();
                        },
                        function (error) {
                            handleFailure(error);
                        });
                }
            }
        };

        vm.reverseEvent = function () {
            dialogService.openDialog("app/funding/partials/reverseEvent.html",
                "reverseEvent.controller",
                {
                    width: "100px",
                    selectedEvent: {
                        uniqueEventId: vm.uniqueEventId,
                        residentId: vm.resident.id,
                        requestType: vm.requestType,
                        residencyId: vm.residencyId,
                        fromILU: vm.fromILU,
                        enquiryId: vm.enquiryId
                    },
                    onModalCloseCallback: vm.reverseModalCloseCallback
                });
        };

        vm.reverseModalCloseCallback = function reverseModalCloseCallback() {
            modalConfig.onModalCloseCallback();
            vm.$hide(); // hiding departure dialog
        }

        function extractDepartureInformation(departureReason) {
            switch (departureReason) {
                case "AnotherAgedCareServiceByProvider":
                    vm.departure.departureReason = "AnotherAgedCareService";
                    vm.departure.departureInitiatedBy = "Provider";
                    break;
                case "AnotherAgedCareServiceByResident":
                    vm.departure.departureReason = "AnotherAgedCareService";
                    vm.departure.departureInitiatedBy = "Resident";
                    break;
                case "AnotherOrganisationFacilityServiceByProvider":
                    vm.departure.departureReason = "Transfer"
                    vm.departure.departureInitiatedBy = "Provider";
                    vm.transferReasonSelected();
                    break;
                case "AnotherOrganisationFacilityServiceByResident":
                    vm.departure.departureReason = "Transfer"
                    vm.departure.departureInitiatedBy = "Resident";
                    vm.transferReasonSelected();
                    break;
                default:
                    vm.departure.departureReason = departureReason;
                    break;
            }
        }

        function mapDepartureInformation() {
            if (vm.departure.departureReason !== "AnotherAgedCareService" && vm.departure.departureReason !== "Transfer")
                return;

            if (vm.departure.departureInitiatedBy === "Provider") {
                vm.departure.departureReason = vm.departure.departureReason === "Transfer" ? "AnotherOrganisationFacilityServiceByProvider" : "AnotherAgedCareServiceByProvider";
            } else if (vm.departure.departureInitiatedBy === "Resident") {
                vm.departure.departureReason = vm.departure.departureReason === "Transfer" ? "AnotherOrganisationFacilityServiceByResident" : "AnotherAgedCareServiceByResident";
            }
        }

        function handleSuccess() {
            vm.$hide();
            //this dialog can be called from multiple screens. Reload the page if no onModalCloseCallback has been passed.
            if (modalConfig.onModalCloseCallback != undefined) {
                modalConfig.onModalCloseCallback();
            } else {
                if (vm.fromILU) {
                    if (vm.isVacating) {
                        $state.go("viewIndependentLivingAllocation", { 'enquiryId': vm.enquiryId, 'residentId': vm.resident.id, 'viewDepartedMode': true });
                    }
                    else {
                        $state.go("viewIndependentLivingAllocation", { 'enquiryId': vm.enquiryId, 'residentId': vm.anotherResident.id, 'viewDepartedMode': false });
                    }
                }
                else {
                    $state.reload();
                }
            }

            if (vm.isCorrection) {
                notification.success("Departure corrected.");
            } else {
                notification.success(vm.isVacating ? "Unit vacated." : "Resident departed.");
            }
        }

        function handleFailure(error) {
            if (!error.modelStateSummary) {
                notification.error("Unable to send " + (vm.isVacating ? "vacating." : "departure.") + " Either your computer is offline, or Resident Manager is having problems of its own.");
            } else {
                var dialogTitle = vm.isCorrection
                    ? "Correction to resident departure"
                    : (vm.isVacating ? "Vacate unit" : "Depart resident");
                dialogService.openMessageDialog(
                    dialogTitle,
                    (vm.isVacating ? "The following errors occurred when submitting the vacate unit event:"
                        : "The following errors occurred when submitting the depart resident event:"),
                    (vm.isVacating ? "unit vacation error" : "residentDeparture-error"),
                    _.values(error.modelStateSummary)
                );
            }
        }

        vm.markAsDisabled = function markAsDisabled() {
            if (vm.fromILU) {
                if (!vm.isVacating) {
                    if (vm.warningOfMissingBillPayer)
                        return true;
                    else
                        return false;
                }
            }
            else {
                return false;
            }
        }
    }
];