import * as _ from 'underscore';
import * as moment from 'moment';
import * as angular from 'angular';
import { IReportingService } from '../services/IReportingService';
import { INotificationService } from '../../common/services/INotificationService';
import { IDateFormattingService } from '../../common/services/IDateFormattingService';
import { IInfiniteLoaderFactory, IInfiniteLoaderService } from '../../common/services/IInfiniteLoaderService';
import { MovementsReportFilter } from '../Models';
import { CareType } from '../Models';
import { DateRange } from '../Models';
import { MovementStatus } from '../Models';
import { IDownloadFileService } from '../../common/services/IDownloadFileService';

class MovementsReportController {
    static $inject = [
        'reporting.service',
        'notification.service',
        'infiniteLoader.service',
        'dateFormatting.service',
        'downloadFile.service'
    ];
    infiniteLoader: IInfiniteLoaderService;
    movements: any[] = [];
    reportFilters: MovementsReportFilter;
    totalCount: number;

    constructor(
        private readonly reportingService: IReportingService,
        private readonly notificationService: INotificationService,
        private readonly infiniteLoaderFactory: IInfiniteLoaderFactory,
        private readonly dateFormattingService: IDateFormattingService,
        private readonly downloadFileService : IDownloadFileService
    ) {
        this.setFilters();
    }

    private setFilters = () => {

        this.reportFilters = new MovementsReportFilter();
        this.reportFilters.dateRange = DateRange.MonthToDate;
        this.reportFilters.careType = CareType.Both;
        this.reportFilters.movementStatus = MovementStatus.Both;

        this.updateFilters();
    }

    private loadReportData = () => {
        this.movements = [];
        this.totalCount = 0;
        this.infiniteLoader = this.infiniteLoaderFactory.instance(this.loadMovements,
            (result, totalCount) => {
                this.movements = this.movements.concat(result);
                this.totalCount = totalCount;
                this.processMovements();
            }, () => {
                this.notificationService.error('Unexpected error occurred while loading report data.');
            });
    }

    private processMovements = () => {
        _.each(this.movements, (item, index) => {
            var admissionDate = moment(item.admissionDateUtc);
            item.addHeader = index === 0 || admissionDate.format('M') !== moment(this.movements[index - 1].admissionDateUtc).format('M');//mark header
        });
    }

    private loadMovements = (notIncludingItemsAfter: string, skip: number, take: number) => {
        if (this.reportFilters.fromDate == undefined || this.reportFilters.toDate == undefined) {
            return; //return if user manually fills the datepicker with wrong date format
        }

        return this.reportingService.getMovementsReportData(notIncludingItemsAfter, skip, take, this.reportFilters);
    }

    updateFilters = () => {
        if (this.reportFilters.dateRange === DateRange.MonthToDate) {
            var filterDate = this.dateFormattingService.snapToClosestUTCMidnight(new Date());
            this.reportFilters.toDate = this.dateFormattingService.snapToClosestUTCMidnight(new Date());
            filterDate.setUTCDate(1); //Sets the first day of month
            this.reportFilters.fromDate = filterDate;
        }
        else if (this.reportFilters.dateRange === DateRange.YearToDate) {
            var filterDate = this.dateFormattingService.snapToClosestUTCMidnight(new Date());
            this.reportFilters.toDate = this.dateFormattingService.snapToClosestUTCMidnight(new Date());
            filterDate.setUTCMonth(0, 1); //Sets the first day of year
            this.reportFilters.fromDate = filterDate;
        }
        this.loadReportData();
    }

    exportReport = () => {
        this.reportingService.getMovementsReportExcelStream(this.reportFilters).then((response) => {
            this.downloadFileService.downloadFile(response, 'MovementsReport.xlsx');
        });
    }
}
export = MovementsReportController;


