import * as angular from 'angular';
import movementsReportController = require("./controllers/MovementsReportController");
import unitsReportController = require("./controllers/UnitsReportController")
import enquirySourceReportController = require("./controllers/EnquirySourceReportController");
import reportingService = require("./services/ReportingService");
import state = require("./States");
import billingExtractComponent = require("./components/billingExtractComponent");
import FinancialDetailsReportComponent = require("./components/financialDetailsReportComponent");
import SettlementsReportComponent = require("./components/settlementsReportComponent");
import ClassificationHistoryReportComponent = require("./components/ClassificationHistoryReportComponent");
import PalliativeAssessmentsReportComponent = require('./components/palliativeAssessmentsReportComponent');
import ReassessmentRequestsReportComponent = require('./components/reassessmentRequestsReportComponent');
import PotentialReassessmentsReportComponent = require( './components/PotentialReassessmentsReportComponent');

var module = angular.module('reporting.module', ["common.module"]);
new state().Configure(module);

module.controller('movementsReport.controller', movementsReportController as ng.IControllerConstructor);
module.controller('enquirySourceReport.controller', enquirySourceReportController as ng.IControllerConstructor);
module.controller('unitsReport.controller', unitsReportController as ng.IControllerConstructor);
module.component("billingExtract", new billingExtractComponent());
module.component("financialDetailsReport", new FinancialDetailsReportComponent());
module.component("palliativeAssessmentsReport", new PalliativeAssessmentsReportComponent());
module.component("settlementsReport", new SettlementsReportComponent());
module.component("classificationHistoryReport", new ClassificationHistoryReportComponent());
module.component("reassessmentRequestsReport", new ReassessmentRequestsReportComponent());
module.component("potentialReassessmentsReport", new PotentialReassessmentsReportComponent());
module.service("reporting.service", reportingService);
