import { ViewEnquiryDto, ResidentDto, UnitDto, IluAdmissionDto, SelectedResidentDto, IluStatus } from "../Models";
import * as _ from 'underscore';
import IndependentLivingService = require("../services/IndependentLivingService");
import { IIndependentLivingService } from "../services/IIndependentLivingService";
import { INotificationService } from "../../common/services/INotificationService";
import type { IStateService } from "angular-ui-router";
import { IDialogService } from "../../common/services/IDialogService";

class AdmitToUnitDialogController {
    static $inject = ["independentLiving.service", "notification.service", "$state", "dialog.service", "modalConfig", "$q"];

    public residentNames: string;
    public availableUnits: Array<UnitDto> = new Array<UnitDto>();
    public selectedUnit: UnitDto;
    public unitNumber: string;
    public location: string;
    public unitType: string;
    public admissionDate: Date;
    public admissionTimeLocal : string;
    public $hide: any;
    public residents: Array<ResidentDto> = new Array<ResidentDto>();
    public responsibleResidencyId?: number;
    public unitValidationFailed: boolean = false;
    public admitToUnitForm: ng.IFormController;
    public isCorrection: boolean = false;
    private isDeparted: boolean = false;

    constructor(private readonly iluService: IIndependentLivingService, 
        private readonly notificationService: INotificationService,
        private readonly $state: IStateService,
        private readonly dialogService: IDialogService,
        private readonly modalConfig: any, 
        private readonly $q: any,
        public enquiry: ViewEnquiryDto = modalConfig.enquiry) {
        if(modalConfig.source === "eventsHistory") {
            this.isCorrection = true;
            if(modalConfig.isDeparted != undefined){
                this.isDeparted = modalConfig.isDeparted;
            }
            this.loadEnquiry(modalConfig.enquiryId);
        }
        else {
            this.setResidentsAndNames();
            this.setInitialUnitDetails();
            this.loadUnits();
        }
    }

    public admit = (form: ng.IFormController) => {
        if(!this.validate() || !form.$valid)
            return;
        
        let iluAdmssionDto: IluAdmissionDto = new IluAdmissionDto();
        iluAdmssionDto.accommodationId = this.selectedUnit.accommodationId;
        iluAdmssionDto.iluAllocationId = this.enquiry.enquiryId;
        iluAdmssionDto.admissionDate = this.admissionDate;
        iluAdmssionDto.admissionTimeLocal = this.admissionTimeLocal;
        iluAdmssionDto.responsibleResidencyId = this.responsibleResidencyId;
        iluAdmssionDto.price = this.selectedUnit.price;
        iluAdmssionDto.residentDetails = [];

        let selectedResidents: Array<SelectedResidentDto> = new Array<SelectedResidentDto>();
        let index  = 0;
        _.forEach(this.enquiry.residents, (resident:ResidentDto) => {
            let selectedResident = new SelectedResidentDto();
            selectedResident.residentId = resident.id;
            selectedResident.residencyId = resident.residencyId;
            selectedResident.fullName = resident.firstName + " " + resident.lastName;
            iluAdmssionDto.residentDetails.push({residentId : resident.id,dateOfBirth: form['residentDateOfBirth'+index].$modelValue});
            selectedResidents.push(selectedResident);
            ++index;
        });

        if(this.isCorrection){
            this.iluService.correctAdmission(iluAdmssionDto).then(result => {
                this.notificationService.success("Admission correction successful.");
                this.$hide();
                if(this.modalConfig.onModalCloseCallback != null)
                    this.modalConfig.onModalCloseCallback('iluAdmissionCorrected');
            }, (error) =>{
                if (!error.modelStateSummary) {
                    this.notificationService.error("Unexpected error while admitting to independent living unit.");
                } else {
                    this.dialogService.openMessageDialog(
                        "Correct resident admission",
                        "The following errors occurred when submitting correction for resident admission:",
                        "residentEntry-error", _.values(error.modelStateSummary));
                }
            });
        }
        else {
            this.iluService.admit(iluAdmssionDto).then(result => {
                this.$state.go("editIndependentLivingAllocation.finance", { enquiryId: this.enquiry.enquiryId, isOccupied:this.enquiry.occupiedDate ? true : false, selectedResidentId: this.residents[0].id, selectedUnit: this.selectedUnit, 
                    selectedResidents: selectedResidents, doesFeeAndChargeExist : true, financialTabSelected : true });
                this.$hide();
            },
            () => {
                this.notificationService.error("Unexpected error while admitting to independent living unit.");
            });
        }
    }

    public reverse = () : void => {
        if(this.isDeparted === true){
            this.dialogService.openMessageDialog("Unable to reverse", "This event cannot be reversed.", "reversal-error");
            return;
        }

        this.dialogService.openDialog("app/funding/partials/reverseEvent.html",
            "reverseEvent.controller",
            {
                width: "100px",
                selectedEvent: {
                    enquiryId: this.enquiry.enquiryId,
                    requestType: "IluAdmission"
                },
                onModalCloseCallback: this.onModalCloseCallback
            });
    }

    private onModalCloseCallback = () => {
        this.modalConfig.onModalCloseCallback("iluAdmissionReversed");
        this.$hide();
    }

    private validate = ():boolean => {
        if(this.selectedUnit == null){
            this.unitValidationFailed = true;
            return false;
        }
            
        return true;
    }

    public bindHide = (hide) => {
        this.$hide = hide;
    };

    private setResidentsAndNames = () => {
        _.forEach(this.enquiry.residents, (resident:ResidentDto) => {
            this.residents.push(resident);
            const residentFullName = resident.firstName + " " + resident.lastName;
            if(this.residentNames == null)
                this.residentNames = residentFullName;
            else{
                this.residentNames += " & " + residentFullName;
            }
        });
    }

    private setInitialUnitDetails = () => {
        //Set the responsible bill payer
        if(this.enquiry.residents.length == 1){
            this.responsibleResidencyId = this.enquiry.residents[0].residencyId;
        }
        else{
            this.responsibleResidencyId = this.enquiry.responsibleResidencyId;
        }

        if(this.enquiry.assignedUnit == null || (this.enquiry.assignedUnit.status.toString() != "Available" && this.modalConfig.source !== 'eventsHistory'))
            return;

        this.selectedUnit = new UnitDto();
        this.selectedUnit.roomNumber =  this.enquiry.assignedUnit.unitNumber; 
        this.selectedUnit.location =  this.enquiry.assignedUnit.location; 
        this.selectedUnit.accommodationName =  this.enquiry.assignedUnit.unitType;
        this.selectedUnit.accommodationId = this.enquiry.assignedUnit.accommodationId;
    }

    private loadUnits = () => {
        let unitsPromise = this.iluService.getUnitsByStatus(IluStatus.Available).then(result => {
            return result;
          }, () => {
            this.notificationService.error("Unexpected error while loading units.");
          });
      
          let accommodationEventsPromise = this.iluService.getAccommodationFinancialEvents().then(events => {
            return events;
          }, () => {
            this.notificationService.error("Unexpected error while loading unit events.");
          });
      
          this.$q.all([unitsPromise, accommodationEventsPromise]).then(result => {
            this.availableUnits = result[0];
            this.iluService.getUnitNotes(this.availableUnits, result[1]);
          });
    }

    private initializeOccupancyDetails():void {
        if(this.enquiry.occupiedDate === null)
            return;

        this.admissionDate = this.enquiry.occupiedDate;
        this.admissionTimeLocal = this.enquiry.residents[0].admissionTimeLocal;
    }

    private loadEnquiry(enquiryId: number) : void {
        this.iluService.getEnquiry(enquiryId, this.isDeparted, null).then((response) => {
            this.enquiry = response;
            this.setResidentsAndNames();
            this.setInitialUnitDetails();
            this.loadUnits();
            this.initializeOccupancyDetails();
        },
        (error) => {
            this.notificationService.error("Unexpected error while attempting to get enquiry");
        });
    }
}
export = AdmitToUnitDialogController;