export enum ResponseType {
    "Accepted" = "Accepted",
    "Rejected" = "Rejected",
    "Duplicate" = "Duplicate",
    "Held" = "Held",
    "Superseeded" = "Superseded",
    "Waiting on response" = "BeingProcessed",
    "Error" = "Error"
}

export enum EasyClaimEventType {
    "Admission" = "ResidentialEntry,ResidentialEntryCorrection,ResidentialEntryReversal",
    "Departure" = "ResidentialDeparture,ResidentialDepartureCorrection,ResidentialDepartureReversal",
    "Leave" = "ResidentialLeave",
    "ACFI" = "ACFI",
}

export enum ProdaEventType {
    "Admission" = "ResidentialEntry,ResidentialEntryCorrection,ResidentialEntryReversal",
    "Departure" = "ResidentialDeparture,ResidentialDepartureCorrection,ResidentialDepartureReversal",
    "Leave" = "ResidentialLeave",
    "ACFI" = "ACFI",
    "Finalise Claim" = "FinaliseClaimEvent"
}
export class MedicareStatementsByYear {
    statementYear: number;
    medicareStatements: MedicareStatementSimple[]
}

export class MedicareStatementsMonthYearByYear {
    statementYear: number;
    medicareStatementsMonthYear: MedicareStatementMonthYear[]
}

export class MedicareStatementSimple {
    statementTotal: number;
    expectedStatementTotal: number;
    statementVariance?: number;
    statementYear: number;
    statementMonth: number;
    isPriorFacilityCreateDate: boolean;
    statementFullMonthAndYear: string;
    isReconciliationFinalised: boolean;
}

export class MedicareMonthYearByYearDto {
    statementYear: number;
    statementMonths: MedicareStatementMonthYear[]
}
export class MedicareStatementMonthYear {
    statementYear: number;
    statementMonth: number;
    statementId : number;
}

export class StatementFilterDataDto {
    statementTotal? : number; 
    expectedStatementTotal? : number;
    varianceStatementTotal? : number;
    isClaimFinalised : boolean;
}

//Variance Summary
export class VarianceStatementSummary {
    supplements: SupplementsVariance;
    acfi: AcfiVariance;
    respite: RespiteVariance;
    accommodation: AccommodationVariance;
    hardship: HardshipVariance;
    totals: TotalsVariance;
}

export class NumberTypeVariance {
    expectedValue: number;
    actualValue: number;
    variance: number;
    matched: boolean;
}

export class StringTypeVariance {
    expectedValue: string;
    actualValue: string;
    matched: boolean;
}

export class SupplementsVariance {
    cleanEnergy: NumberTypeVariance
    dementia: NumberTypeVariance
    veterans: NumberTypeVariance
    workforce: NumberTypeVariance
    homeless: NumberTypeVariance
    payrollTax: NumberTypeVariance
    exHostel: NumberTypeVariance
    transitional: NumberTypeVariance
    viability: NumberTypeVariance
    chargeExempt: NumberTypeVariance
    pensioner: NumberTypeVariance
    hotelling: NumberTypeVariance
    outbreakManagementSupport: NumberTypeVariance
    registeredNurse24x7: NumberTypeVariance
    residentContributionTopUp: NumberTypeVariance
    oxygen: NumberTypeVariance
    enteralFeeding: NumberTypeVariance
    adjustments: NumberTypeVariance
    conditionalAdjustment: NumberTypeVariance
    incomeTestedReviewRefund: NumberTypeVariance
    incomeTestedReduction: NumberTypeVariance
    meansTestedReviewRefund: NumberTypeVariance
    meansTestedReduction: NumberTypeVariance
}

export class AcfiVariance {
    amountPaid: NumberTypeVariance;
    adjustments: NumberTypeVariance
}

export class RespiteVariance {
    careAllocation: NumberTypeVariance;
    daysUsedThisMonth: NumberTypeVariance;
    daysUsedYearToDate: NumberTypeVariance;
    periodStart: StringTypeVariance
    periodEnd: StringTypeVariance
    incentiveAllocation: NumberTypeVariance;
    respiteIncentiveUsage: NumberTypeVariance;
    incentivePayment: NumberTypeVariance;
    residentialRespitePercentAchieved: NumberTypeVariance;
    amountPaid: NumberTypeVariance;
    adjustments: NumberTypeVariance;
}

export class AccommodationVariance {
    accommodationSupplement: NumberTypeVariance;
    concessionalAssisted: NumberTypeVariance;
    accommodationChargeTopUp: NumberTypeVariance;
    transitionalAccommodation: NumberTypeVariance;
}

export class HardshipVariance {
    ra: NumberTypeVariance;
    individual: NumberTypeVariance;
    classes: NumberTypeVariance;
    classCArrears: NumberTypeVariance;
}

export class TotalsVariance {
    outstandingBalance: NumberTypeVariance;
    subtotal: NumberTypeVariance;
    lessAdvance: NumberTypeVariance;
    secondPaymentDueHeldover: NumberTypeVariance;
    grantRelatedAdditionalFunding: NumberTypeVariance;
    capitalTransitionPayment: NumberTypeVariance;
    totalAmountCalculated: NumberTypeVariance;
    totalAmountPaid: NumberTypeVariance;
}

//Variance statement payments
export class VarianceStatementPayments {
    isReconciliationFinalised: boolean;
    expectedTotal: number;
    actualTotal: number;
    respitePaymentVariance: StatementVarianceRespitePayment[];
    permanentPaymentVariance: StatementVariancePermanentPayment[];
}

export class PaymentLine {
    paymentType: String;
    paidCare: number;
    unpaidCare: number;
    dailyRate: number;
    amountDue: number;
    action: ReconciliationAdjustmentAction;
    markedForAction: boolean;
    restrictFromAction: boolean;
    groupIdentifier: String;
    matched: boolean;
    matchIdentifier: String;
    notes: NoteInfo[];
}

export class NoteInfo {
    note: string;
    entryDateTime: Date;
    userFirstName: string;
    userLastName: string;
}

export class StatementVarianceRespitePayment {
    residentId: number;
    uniqueResidentManagerIdentifier: String;
    agreementId: String;
    careRecipientId: String;
    lastName: String;
    firstName: String;
    firstNameInMedicare: String;
    lastNameInMedicare: String;
    expectedTotal: number;
    actualTotal: number;
    expectedPaymentsVariance: PaymentLine[];
    actualPaymentsVariance: PaymentLine[];
    adjustments: RespiteAdjustment[];
    expectedCarriedOver: RespiteAdjustment[];
    actualCarriedOver: RespiteAdjustment[];
    selectAllExpected: boolean;
    selectAllActual: boolean;
}

export class RespiteAdjustment {
    adjustmentMonth: string;
    payments: PaymentLine[];
}

export class StatementVariancePermanentPayment {
    residentId: number;
    careRecipientId: String;
    lastName: String;
    firstName: String;
    firstNameInMedicare: String;
    lastNameInMedicare: String;
    expectedTotal: number;
    actualTotal: number;
    expectedPaymentsVariance: PermanentPaymentLine[];
    actualPaymentsVariance: PermanentPaymentLine[];
    adjustments: PermanentAdjustment[];
    expectedCarriedOver: PermanentAdjustment[];
    actualCarriedOver: PermanentAdjustment[];
    selectAllExpected: boolean;
    selectAllActual: boolean;
}

export class PermanentPaymentLine extends PaymentLine {
    effectiveDate: String;
    paidLeaveSocial: number;
    paidLeaveHospital: number;
    paidLeaveTransition: number;
}

export class PermanentAdjustment {
    adjustmentMonth: string;
    payments: PermanentPaymentLine[];
}

export class StatementAdjustment {
    paymentType: String;
    paidCare: number;
    unpaidCare: number;
    dailyRate: number;
    amountDue: number;
    careRecipientId: String;
    careType: CareType;
    paidLeaveSocial: number;
    paidLeaveHospital: number;
    paidLeaveTransition: number;
    medicareStatementType: MedicareStatementType;
    adjustmentAmount: number;
    action: ReconciliationAdjustmentAction;
    originalAdjustmentMonth?: String;
    groupIdentifier?: String;
    firstName: String;
    lastName: String;
    isCarriedOver?: boolean = false;
}

export class ReconciliationAdjustmentsSummary{
    type: String;
    totalAmount: number;
    adjustments: ReconciliationAdjustmentSummaryRow[]
}

export class ReconciliationAdjustmentSummaryRow{
    paymentType: String;
    residentName: String;
    days: number;
    rate: number;
    adjustmentAmount: number;
}

export enum ReconciliationAdjustmentAction {
    None = <any>"None",
    MatchAccept = <any>"MatchAccept",
    Accept = <any>"Accept",
    Undo = <any>"Undo",
    Match = <any>"Match",
    Writeoff = <any>"Writeoff",
    CarryOver = <any>"CarryOver",
    AutoMatched = <any>"AutoMatched"
}

export enum CareType {
    Permanent = 0,
    Respite = 1
}

export enum MedicareStatementType {
    Expected = 1,
    Actual = 2
}