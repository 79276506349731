"use strict";

module.exports = function () {
    return {
        restrict: "E",
        scope: {
            availableUnits: "=",
            selectedUnit: "=",
            selectedUnitId: "=",
            onchange: "="
        },
        controller: "unitAssignment.controller",
        controllerAs: "vm",
        replace: false,
        templateUrl: "app/common/directives/partials/unitAssignment.html"
    };
}