module.exports = [
    function () {
        
        function newGuid() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
              var r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
              return v.toString(16);
            });

        }

        function getStateDescription(state) {
            var shortDesc;
            switch (state) {
                case "Australian Capital Territory":
                    shortDesc = "ACT";
                    break;
                case "New South Wales":
                    shortDesc = "NSW";
                    break;
                case "Northern Territory":
                    shortDesc = "NT";
                    break;
                case "Queensland":
                    shortDesc = "QLD";
                    break;
                case "South Australia":
                    shortDesc = "SA";
                    break;
                case "Tasmania":
                    shortDesc = "TAS";
                    break;
                case "Victoria":
                    shortDesc = "VIC";
                    break;
                case "Western Australia":
                    shortDesc = "WA";
                    break;
                default:
                    shortDesc = state;
                    break;
            }
            return shortDesc;
        }
        return {
            newGuid: newGuid,
            getStateDescription: getStateDescription
        }
    }
];