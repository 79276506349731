import { IResidentsService } from "../services/IResidentsService";
import { INotificationService } from "../../common/services/INotificationService";
import _ = require("underscore");
import { IDialogService } from "../../common/services/IDialogService";
import { ResidentEventType, EventHistory, EventsHistoryRow, CareType } from "../Models";

class ResidentEventHistoryComponent implements ng.IComponentOptions {
    public bindings: any = {};
    public templateUrl: string = 'app/residents/partials/residentEventHistory.html';

    constructor() {
        this.bindings = {
            residentId: "="
        };
    }

    public controller: any = class ResidentEventHistoryController {
        static $inject = ['residents.service', 'notification.service', 'dialog.service', '$state'];

        public eventHistory: EventHistory;

        private residentId: number;

        constructor(private readonly residentService: IResidentsService,
            private readonly notificationService: INotificationService,
            private readonly dialogService: IDialogService,
            private $state: ng.ui.IStateService) {
            this.loadEventHistory();
        }

        private loadEventHistory = () => {
            this.residentService.getEventHistory(this.residentId).then((response) => {
                this.eventHistory = response;
            }, (error) => {
                this.notificationService.error("Unexpected error while retrieving resident event history");
            })
        }

        public editEvent = (id: number): void => {
            const eventHistoryItem = _.find(this.eventHistory.eventsHistory, (event) => {
                return event.id == id;
            });

            if (this.canEdit(eventHistoryItem)) {
                const { template, controller, modelConfig } = this.getTemplate(eventHistoryItem.eventType, eventHistoryItem);
                this.dialogService.openDialog(template, controller, modelConfig);
            }
        }

        public editLeave = (id: number) => {
            const leaveEvent = _.find(this.eventHistory.leaves, (leave) => {
                return leave.id == id;
            });

            this.dialogService.openDialog("app/residents/partials/sendOnLeaveDialog.html", "sendOnLeaveDialog.controller", {
                resident: {
                    id: this.residentId,
                    firstName: this.eventHistory.latestResidency.firstName,
                    lastName: this.eventHistory.latestResidency.lastName,
                    careType: leaveEvent.careType,
                    leave: {
                        id: leaveEvent.id,
                        facility: leaveEvent.facility,
                        facilityId: leaveEvent.facilityId,
                        startDateUtc: leaveEvent.startDateUtc,
                        leaveStartTimeLocalString: leaveEvent.leaveStartTimeLocalString,
                        expectedReturnDateUtc: leaveEvent.expectedReturnDateUtc,
                        type: leaveEvent.type,
                        isReversed: leaveEvent.isReversed,
                        comments: leaveEvent.comments,
                        returnDateUtc: leaveEvent.returnDateUtc,
                        leaveReturnTimeLocalString: leaveEvent.leaveReturnTimeLocalString,
                        respiteLeaveFacilityIdWithinResman: leaveEvent.respiteLeaveFacilityIdWithinResman,
                        isRespiteLeaveWithinOrganisation: leaveEvent.isRespiteLeaveWithinOrganisation,
                        respiteLeaveExternalFacilityDetails: leaveEvent.respiteLeaveExternalFacilityDetails
                    }
                },
                uniqueEventId: leaveEvent.uniqueEventId,
                isCorrection: true,
                origin: "eventsHistory",
                requestType: "ResidentialLeave",
                onModalCloseCallback: this.modalClose()
            });
        }

        private modalClose(residentid?: number, enquiryId?: number) {
            const residentId = residentid;
            const iluEnquiryId = enquiryId;
            const localState = this.$state;

            return function onModalClose(operation?: string) {
                if (operation === 'admissionReversed') {
                    localState.go('viewLead.events', { residentId: residentId });
                }
                else if (operation === 'departureReversed' || operation === 'iluAdmissionCorrected' || operation === 'admissionCorrection') {
                    localState.reload();
                }
                else if (operation === 'iluAdmissionReversed') {
                    localState.go('viewEnquiry.events', { enquiryId: iluEnquiryId, residentId: residentId });
                }
                else {
                    localState.reload();
                }
            }
        }

        private getTemplate = (eventType: ResidentEventType, eventsHistoryItem: EventsHistoryRow): any => {
            switch (<any>eventType) {
                case "ResidentAdmitted":
                case "ResidentAdmissionCorrected":
                    let templateUrl = "app/residents/partials/residentAdmissionDialog.html";
                    let controller = "admissionCorrectionDialog.controller";
                    let modelConfig: any;
                    if (eventsHistoryItem.careType.toString() === "ILU") {
                        templateUrl = "app/independentLiving/partials/admitToUnitDialog.html";
                        controller = "admitToUnitDialogController.controller";
                        modelConfig = {
                            enquiryId: eventsHistoryItem.enquiryId,
                            source: "eventsHistory",
                            isDeparted: eventsHistoryItem.isDeparted,
                            onModalCloseCallback: this.modalClose(this.residentId, eventsHistoryItem.enquiryId)
                        }
                    }
                    else {
                        modelConfig = {
                            residentId: this.residentId,
                            uniqueEventId: eventsHistoryItem.uniqueEventId,
                            isCorrection: true,
                            fromEventHistory: true,
                            requestType: !eventsHistoryItem.isPrivateResident ? eventsHistoryItem.medicareRequestType : 'ResidentialEntryCorrection',
                            isDeparted: eventsHistoryItem.isDeparted,
                            onModalCloseCallback: this.modalClose(this.residentId)
                        }
                    }

                    return {
                        template: templateUrl, controller: controller, modelConfig: modelConfig
                    };
                case "ResidentDeparted":
                case "ResidentDepartureCorrected":
                    const admittedHistoryEvents = this.eventHistory.eventsHistory.filter(event =>
                        (event.eventType === "ResidentAdmitted" || event.eventType === "ResidentAdmissionCorrected") &&
                        this.eventHistory.latestResidency.admissionDate > eventsHistoryItem.eventDate
                    );
                    const filteredLeaves = this.eventHistory.leaves.filter(leave =>
                        leave.returnDateUtc <= eventsHistoryItem.eventDate
                    );

                    // Determine if there are admissions after departure
                    const hasAdmissionAfterDeparture = admittedHistoryEvents.length > 0;

                    // Determine the latest leave end date before current event date
                    const leaveEndDate = filteredLeaves.length > 0
                        ? filteredLeaves[0].returnDateUtc
                        : null;

                    let templateResult = {
                        template: "app/residents/partials/departureDialog.html", controller: "departureDialog.controller", modelConfig: {
                            resident: {
                                id: this.residentId,
                                firstName: this.eventHistory.latestResidency.firstName,
                                lastName: this.eventHistory.latestResidency.lastName,
                                careType: eventsHistoryItem.careType
                            },
                            residencyId: eventsHistoryItem.residencyId,
                            uniqueEventId: eventsHistoryItem.uniqueEventId,
                            isCorrection: true,
                            fromEventHistory: true,
                            requestType: eventsHistoryItem.careType.toString() === "ILU"
                                ? "ResidentialILUDepartureCorrection"
                                : !eventsHistoryItem.isPrivateResident
                                    ? eventsHistoryItem.medicareRequestType
                                    : "ResidentialDepartureCorrection",
                            fromILU: eventsHistoryItem.careType.toString() === "ILU",
                            enquiryId: eventsHistoryItem.enquiryId,
                            leaveEndDate: leaveEndDate,
                            hasAdmissionAfterDeparture: hasAdmissionAfterDeparture,
                            onModalCloseCallback: this.modalClose(this.residentId, eventsHistoryItem.enquiryId)
                        }
                    }
                    return templateResult;
            }
        }

        public canEdit = (editingHistoryItem: EventsHistoryRow): boolean => {

            if (editingHistoryItem.careType.toString() == "ILU" && editingHistoryItem.eventType.toString() == "ResidentDeparted" && editingHistoryItem.independentLivingAllocationWasSettled) {
                this.dialogService.openMessageDialog(
                    "Unable to edit",
                    "Departure cannot be edited.<br/>" +
                    "<span class='error-details'>Settlement has been finalised.</span>",
                    "correction-error");
                return false;
            }

            if (<any>editingHistoryItem.eventType == "ResidentAdmissionReversed" || <any>editingHistoryItem.eventType == "ResidentDepartureReversed") {
                this.dialogService.openMessageDialog("Unable to edit", "This event cannot be edited.", "correction-error");
                return false;
            }

            if (this.eventHistory.currentLoggedInFaclityId != editingHistoryItem.facilityId) {
                this.dialogService.openMessageDialog("Unable to edit", "This event cannot be edited as the event did not occur in logged in facility.", "correction-error");
                return false;
            }

            if (editingHistoryItem.careType.toString() !== "ILU" && this.eventHistory.latestResidency.admissionDate == null) {

                let message = "This event cannot be edited as this resident is currently a lead.";

                if (editingHistoryItem.facilityId != this.eventHistory.latestResidency.facilityId) {
                    message = `This event cannot be edited as this resident has been transferred to ${this.eventHistory.latestResidency.facilityName}. To modify this event you must delete the lead in ${this.eventHistory.latestResidency.facilityName}`;
                }

                this.dialogService.openMessageDialog("Unable to edit", message, "correction-error");

                return false;
            }

            if (editingHistoryItem.careType.toString() !== "ILU" && this.eventHistory.latestResidency.id != editingHistoryItem.residencyId) {

                this.dialogService.openMessageDialog("Unable to edit", "This event cannot be edited as this resident has been readmitted.",
                    "correction-error");
                return false;
            }
            return true;
        }
    }
}

export = ResidentEventHistoryComponent;