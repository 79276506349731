"use strict";
const moment = require("moment");
const _ = require("underscore");
const multiSelect = require("angularjs-dropdown-multiselect");
var models = require("./../Models");

module.exports = [
    "funding.service", "notification.service", "$state", "dialog.service", "infiniteLoader.service", "$rootScope", "$scope", "featureFlags.service",
    function (fundingService, notification, $state, dialogService, infiniteLoaderService, $rootScope, $scope, featureFlagsService) {
        var vm = this;
        initializeFilters();

        fundingService.lastStatusFetchInformation()
            .then(function (result) {
                if (result.lastMedicareStatusFetchDateTimeUtc && result.lastMedicareStatusFetchDateTimeUtc !== null) {
                    vm.lastUpdatedUtc = result.lastMedicareStatusFetchDateTimeUtc;
                }
            });

        vm.loadMedicareEvents = function () {
            vm.medicareEvents = [];
            vm.infiniteLoader = infiniteLoaderService.instance(getMedicareEvents,
                function (result) {
                    vm.medicareEvents = vm.medicareEvents.concat(result);
                    markEventsLatestInSequences();
                    markEventWithAHeader();
                    if (!vm.selectedEvent && vm.medicareEvents.length > 0) {
                        vm.eventSelected(vm.medicareEvents[0]);
                    }
                },
                function () {
                    notification.error("Unable to display Medicare events. Either your computer is offline or Resident Manager is having problems of it's own.");
                });
        }

        //this method will be called on each filter change
        vm.getUpdatedData = function () {
            vm.medicareEvents = [];
            vm.selectedEvent = '';
            vm.medicareEventsFilter.eventTypes = "All";

            //If all the dropdown items are checked or unchecked then all the data will be fetched. Otherwise selected filters will be applied.

            if (vm.selectedEventTypes.length > 0 && vm.selectedEventTypes.length < Object.keys(models.ProdaEventType).length) {
                vm.medicareEventsFilter.eventTypes = vm.selectedEventTypes.map(function (element) {
                    return element.id;
                }).join(",");
            }

            vm.medicareEventsFilter.responseTypes = "All";
            if (vm.selectedResponseTypes.length > 0 && vm.selectedResponseTypes.length < Object.keys(models.ResponseType).length) {
                vm.medicareEventsFilter.responseTypes = vm.selectedResponseTypes.map(function (element) {
                    return element.id;
                }).join(",");;
            }

            vm.medicareEventsFilter.selectedResidentIds = [];
            if(vm.selectedResidents && vm.selectedResidents.length > 0) {
                vm.medicareEventsFilter.selectedResidentIds = vm.selectedResidents.map(o => o.id);                
            }

            vm.loadMedicareEvents();
        };

        vm.clearFilters = function () {
            resetFilters();
            vm.getUpdatedData();
        };

        vm.fetchStatus = function () {
            fundingService.fetchStatus()
                .then(function () {
                    $state.reload();
                },
                function (response) {
                    if (response) {
                        notification.error("Unable to update events. Medicare is currently not responding.");
                    } else {
                        notification.error("Unable to update events. Either your computer is offline or Resident Manager is having problems of it's own.");
                    }
                });
        }

        vm.eventSelected = function (event) {
            vm.selectedEvent = event;
            vm.selectedEventDetails = null;
            if(vm.showAditionalDetailsSection()) {
                fundingService.getEventDetails(event.id, event.requestType)
                .then(function (result) {
                    vm.selectedEventDetails = result;
                });
            }
        }

        vm.showAditionalDetailsSection = function(){
            if(!vm.selectedEvent)
                return false;
            
            var result =  vm.selectedEvent.requestType === "ResidentialEntry"
                || vm.selectedEvent.requestType === "ResidentialEntryReversal" 
                || vm.selectedEvent.requestType === "ResidentialEntryCorrection" 
                || vm.selectedEvent.requestType === "ResidentialDeparture" 
                || vm.selectedEvent.requestType === "ResidentialDepartureCorrection"
                || vm.selectedEvent.requestType === "ResidentialDepartureReversal"
                || vm.selectedEvent.requestType === "ResidentialLeave"
                || vm.selectedEvent.requestType === "ResidentialLeaveUpdated" 
                || vm.selectedEvent.requestType === "ResidentialLeaveReturned"  
                || vm.selectedEvent.requestType === "ResidentialLeaveReversed"
                || vm.selectedEvent.requestType === "FinaliseClaimEvent"
                || (vm.selectedEvent.requestType === "ACFI" && (vm.selectedEvent.eventStatus === "Rejected" || vm.selectedEvent.eventStatus === "Held"));
            return result;
        }

        vm.showReverseButton = function() {
            if(!vm.selectedEvent)
            return false;

            var result = vm.selectedEvent.residentPalliativeStatus !== 'PalliativeOnEntry' 
                         || vm.selectedEvent.residentPalliativeStatus === 'PalliativeOnEntry'  
                            && (vm.selectedEvent.requestType !== 'ResidentialEntry' && vm.selectedEvent.requestType !== 'ResidentialEntryCorrection');
            return result;
        };

        vm.showPalliativeAdmissionMessage = function() {
            if(!vm.selectedEvent)
            return false;

            var result = (vm.selectedEvent.requestType === 'ResidentialEntry' || vm.selectedEvent.requestType === 'ResidentialEntryCorrection') && vm.selectedEvent.residentPalliativeStatus === 'PalliativeOnEntry';
            return result;
        };

        vm.makeCorrection = function () {
            if (!vm.selectedEvent.isCurrentResidency) {
                var message = vm.selectedEvent.currentResidencyStatus == "Lead"
                    ? "This event cannot be corrected as this resident is currently a lead."
                    : "This event cannot be corrected as this resident has been readmitted.";
                dialogService.openMessageDialog(
                    "Unable to correct",
                    message,
                    "correction-error");
            } else {
                if (vm.selectedEvent.isResidentTransferred) {
                    var message = "This event cannot be corrected as this resident has been transferred to " + vm.selectedEvent.transferredToFacilityName
                        + ". To modify this event you must delete the lead in " + vm.selectedEvent.transferredToFacilityName;
                    dialogService.openMessageDialog("Unable to correct", message, "correction-error");
                }
                else {
                    switch (vm.selectedEvent.requestType) {
                    case "ResidentialEntryCorrection":
                    case "ResidentialEntry":
                        dialogService.openDialog("app/residents/partials/residentAdmissionDialog.html",
                            "admissionCorrectionDialog.controller",
                            {
                                residentId: vm.selectedEvent.residentId,
                                uniqueEventId: vm.selectedEvent.uniqueEventId,
                                isCorrection: true,
                                onModalCloseCallback: vm.reloadDataWithFiltersOnPopupClose,
                            });
                        break;
                    case "ResidentialDeparture":
                    case "ResidentialDepartureCorrection":
                        dialogService.openDialog("app/residents/partials/departureDialog.html",
                            "departureDialog.controller",
                            {
                                resident: {
                                    id: vm.selectedEvent.residentId,
                                    firstName: vm.selectedEvent.residentFirstName,
                                    lastName: vm.selectedEvent.residentLastName,
                                    careType: vm.selectedEvent.residentCareType
                                },
                                uniqueEventId: vm.selectedEvent.uniqueEventId,
                                isCorrection: true,
                                onModalCloseCallback: vm.reloadDataWithFiltersOnPopupClose
                            });
                        break;
                    case "ResidentialLeave":
                    case "ResidentialLeaveUpdated": 
                    case "ResidentialLeaveReturned":
                        dialogService.openDialog("app/residents/partials/sendOnLeaveDialog.html",
                            "sendOnLeaveDialog.controller",
                            {
                                resident: {
                                    id: vm.selectedEvent.residentId,
                                    firstName: vm.selectedEvent.residentFirstName,
                                    lastName: vm.selectedEvent.residentLastName,
                                    careType: vm.selectedEvent.residentCareType,
                                    leave: {
                                        id: vm.selectedEvent.residentLeaveMedicareEvent.residentLeaveId,
                                        startDateUtc: vm.selectedEvent.residentLeaveMedicareEvent.residentLeaveStartDateUtc,
                                        leaveStartTimeLocalString: vm.selectedEvent.residentLeaveMedicareEvent.residentLeaveStartTimeLocalString,
                                        expectedReturnDateUtc: vm.selectedEvent.residentLeaveMedicareEvent.residentLeaveExpectedReturnDateUtc,
                                        type: vm.selectedEvent.residentLeaveMedicareEvent.residentLeaveType,
                                        isReversed: vm.selectedEvent.residentLeaveMedicareEvent.residentLeaveIsReversed,
                                        comments: vm.selectedEvent.residentLeaveMedicareEvent.residentLeaveComments,
                                        returnDateUtc: vm.selectedEvent.residentLeaveMedicareEvent.residentLeaveReturnDateUtc,
                                        leaveReturnTimeLocalString : vm.selectedEvent.residentLeaveMedicareEvent.residentLeaveReturnTimeLocalString
                                    }
                                },
                                uniqueEventId: vm.selectedEvent.uniqueEventId,
                                isCorrection: true,
                                origin: "events",
                                onModalCloseCallback: vm.reloadDataWithFiltersOnPopupClose
                            });
                        break;
                    default:
                        notification.error("This event can't be corrected.");
                    }
                }
            }
        }

        vm.getResidentLinkUrl = function () {
            if (vm.selectedEvent.currentResidencyStatus === "Lead") {
                return $state.href('viewLead', { residentId: vm.selectedEvent.residentId });
            } else {
                return $state.href('viewResident', { residentId: vm.selectedEvent.residentId });
            }
        };

        vm.reverseEvent = function () {
            if (!vm.selectedEvent.isCurrentResidency) {
                var message = vm.selectedEvent.currentResidencyStatus == "Lead"
                        ? "This event cannot be reversed as this resident is currently a lead."
                        : "This event cannot be reversed as this resident has been readmitted.";
                dialogService.openMessageDialog(
                    "Unable to reverse",
                    message,
                    "reversal-error");
            } else {
                if (vm.selectedEvent.isResidentTransferred) {
                    var message = "This event cannot be reversed as this resident has been transferred to " + vm.selectedEvent.transferredToFacilityName
                        + ". To modify this event you must delete the lead in " + vm.selectedEvent.transferredToFacilityName;
                    dialogService.openMessageDialog("Unable to reverse", message, "reversal-error");
                }
                else if(vm.selectedEvent.isDeparted && (vm.selectedEvent.requestType == "ResidentialEntryCorrection" || vm.selectedEvent.requestType == "ResidentialEntry")){
                    notification.error("This event can't be reversed.");
                }
                else {
                    switch (vm.selectedEvent.requestType) {
                    case "ResidentialEntryCorrection":
                    case "ResidentialEntry":
                    case "ResidentialDeparture":
                    case "ResidentialDepartureCorrection":
                    case "ResidentialLeave":
                    case "ResidentialLeaveUpdated": 
                    case "ResidentialLeaveReturned":
                        dialogService.openDialog("app/funding/partials/reverseEvent.html",
                            "reverseEvent.controller",
                            {
                                width: "100px",
                                selectedEvent: vm.selectedEvent,
                                onModalCloseCallback: vm.reloadDataWithFiltersOnPopupClose
                            });
                        break;
                    default:
                        notification.error("This event can't be reversed.");
                    }
                }
            }
        };

        //this method reloads the data with applied filters and keeps the selected event intact.
        vm.reloadDataWithFiltersOnPopupClose = function () {
            vm.getUpdatedData();
        };

        function markEventsLatestInSequences() {
            _.chain(vm.medicareEvents)
                .groupBy('uniqueEventId')
                .each(function (eventsInSequence) {
                    var lastEventInSequence = _.max(eventsInSequence, function (event) { return event.id; });
                    _.each(eventsInSequence, function (event) {
                        event.lastInSequence = event === lastEventInSequence;
                        event.lastEventInSequence = lastEventInSequence;
                    });
                });

        };

        function markEventWithAHeader() {
            for (var i = 0; i < vm.medicareEvents.length; i++) {
                vm.medicareEvents[i].addHeader = i === 0 ||
                    moment(vm.medicareEvents[i].createdOnUtc).format('M') !==
                    moment(vm.medicareEvents[i - 1].createdOnUtc).format('M');
                if (i !== 0 && vm.medicareEvents[i].addHeader) {
                    vm.medicareEvents[i - 1].lastInGroup = true;
                }
            }
        }

        //this event will be called on page load as well as on Clear All link click.
        function resetFilters() {
            vm.medicareEventsFilter = {
                fromDate: '',
                toDate: '',
                eventTypes:'All',
                responseTypes:'All',
                selectedResidentIds:[]
            };
            vm.eventTypes = [];
            vm.responseTypes = [];
            vm.selectedEventTypes = [];
            vm.selectedResponseTypes = [];
            vm.selectedResidents = [];

            //convert dropdown list items to multi-select dropwdown readable format {id:'', lable:''}

            _.each(Object.keys(models.ProdaEventType), function (key) {
                vm.eventTypes.push({
                    id: models.ProdaEventType[key],
                    label: key
                });
            });

            _.each(Object.keys(models.ResponseType), function (key) {
                vm.responseTypes.push({
                    id: models.ResponseType[key],
                    label: key
                });
            });

            //By default all the filters are selected. So copying all the filters to selected filters list.
            angular.copy(vm.eventTypes, vm.selectedEventTypes);
            angular.copy(vm.responseTypes, vm.selectedResponseTypes);
        };

        function initializeFilters() {
            resetFilters();

            //One time settings for multi-select filter
            vm.filterTypeSettings = {
                checkBoxes: true,
                smartButtonMaxItems: 3,
                idProperty: 'label',
                smartButtonTextConverter: function (itemText) {
                    return itemText;
                }
            };

            vm.translationText = {
                checkAll: "All",
                uncheckAll: "None"
            };

            vm.multiSelectCheckboxEvents = {
                onItemSelect: function (item) {
                    vm.getUpdatedData();
                },
                onItemDeselect: function (item) {
                    vm.getUpdatedData();
                },
                onSelectAll: function (item) {
                    vm.getUpdatedData();
                }
            };

            vm.selectedResidents =  [];            

            $scope.$watch("vm.selectedResidents", function() {
                    vm.getUpdatedData();
            }, true);
        };

        function getMedicareEvents(notIncludingItemsAfter, skip, take) {
            return fundingService.getMedicareEvents(notIncludingItemsAfter, skip, take, vm.medicareEventsFilter);
        }
    }
];
