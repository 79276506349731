class State {
    static inject = ['movementsReport.controller', '$state', 'authentication.service'];
    Configure = (module: ng.IModule) => {
        module.config(["$stateProvider", ($stateProvider) => {
            $stateProvider.state("movementsReport", {
                url: "/reporting/movements",
                templateUrl: "app/reporting/partials/movementsReport.html",
                controller: "movementsReport.controller as vm",
                resolve: {
                    authorized: ["authentication.service", "$state", (auth, $state) => {
                        return auth.userHasPermissionDeferred((x) => { return x.reportingMovements.canAction; });
                    }]
                }
            });
            $stateProvider.state("unitsReport", {
                url: "/reporting/units",
                templateUrl: "app/reporting/partials/unitsReport.html",
                controller: "unitsReport.controller as vm",
                resolve: {
                    authorized: ["authentication.service", "$state", (auth, $state) => {
                        return auth.userHasPermissionDeferred((x) => { return x.reportingAccommodations.canAction; });
                    }]
                }
            });
            $stateProvider.state("settlementsReport", {
                url: "/reporting/settlements",
                template: "<settlements-report></settlements-report>",
                resolve: {
                    authorized: ["authentication.service", "$state", (auth, $state) => {
                        return auth.userHasPermissionDeferred((x) => { return x.reportingSettlements.canAction; });
                    }]
                }
            });
            $stateProvider.state("billingExtract", {
                url: "/reporting/billing-extract",
                template: "<billing-extract></billing-extract>",
                resolve: {
                    authorized: ["authentication.service", "$state", (auth, $state) => {
                        return auth.userHasPermissionDeferred((x) => { return true; });
                    }]
                }
            })
            $stateProvider.state("financialDetailsReport", {
                url: "/reporting/financial-details",
                template: "<financial-details-report></financial-details-report>",
                resolve: {
                    authorized: ["authentication.service", "$state", (auth, $state) => {
                        return auth.userHasPermissionDeferred((x) => { return true; });
                    }]
                }
            })
            $stateProvider.state("palliativeAssessmentsReport", {
                url: "/reporting/palliative-assessments",
                template: "<palliative-assessments-report></palliative-assessments-report>",
                resolve: {
                    authorized: ["authentication.service", "$state", (auth, $state) => {
                        return auth.userHasPermissionDeferred((x) => { return true; });
                    }]
                }
            })
            $stateProvider.state("potentialReassessmentsReport", {
                url: "/reporting/potential-reassessments",
                template: "<potential-reassessments-report></potential-reassessments-report>",
                resolve: {
                    authorized: ["authentication.service", "$state", (auth, $state) => {
                        return auth.userHasPermissionDeferred((x) => { return true; });
                    }]
                }
            })
            $stateProvider.state("enquirySourceReport", {
                url: "/reporting/enquiry-source",
                templateUrl: "app/reporting/partials/enquirySourceReport.html",
                controller: "enquirySourceReport.controller as vm",
                resolve: {
                    authorized: ["authentication.service", "$state", (auth, $state) => {
                        return auth.userHasPermissionDeferred((x) => { return true; });
                    }]
                }
            })
            $stateProvider.state("classificationHistoryReport", {
                url: "/reporting/classification-history",
                template: "<classification-history-report></classification-history-report>",
                resolve: {
                    authorized: ["authentication.service", "$state", (auth, $state) => {
                        return auth.userHasPermissionDeferred((x) => { return true; });
                    }]
                }
            })
            $stateProvider.state("reassessmentRequestsReport", {
                url: "/reporting/reassessment-requests",
                template: "<reassessment-requests-report></reassessment-requests-report>",
                resolve: {
                    authorized: ["authentication.service", "$state", (auth, $state) => {
                        return auth.userHasPermissionDeferred((x) => { return true; });
                    }]
                }
            })
        }]);
    }
}

export = State;
