import { IFundingService } from "../Interfaces";
import { INotificationService } from "../../common/services/INotificationService";
import { VarianceStatementSummary, SupplementsVariance } from "../Models";

class VarianceStatementSummaryComponent implements ng.IComponentOptions {
    public bindings: any;
    public templateUrl: string = 'app/funding/partials/varianceStatementSummary.html';

    constructor() {
        this.bindings = {
            monthAndYear: '='
        };
    }

    public controller: any = class VarianceStatementComponentController {
        static $inject = ["funding.service", "notification.service", "$filter"];

        public monthAndYear: string;
        public varianceStatementSummary: VarianceStatementSummary;

        constructor(private readonly fundingService: IFundingService,
            private readonly notificationService: INotificationService,
            private readonly filter: any) {
            this.getSummary();
        }

        public getVariance = (input:number): string => {
            if(input == 0) return '0.00';
            return input < 0 ? `(${this.filter('currency')(Math.abs(input), '', 2)})` : this.filter('currency')(input, '', 2);
        }

        private getSummary = () => {
            this.fundingService.getVarianceSummary(this.monthAndYear).then((result) => {
                this.varianceStatementSummary = result;
            },
                () => {
                    this.notificationService.error("Unexpected error occurred while fetching the variance summary.");
                }
            );
        }
    }
}
export = VarianceStatementSummaryComponent;