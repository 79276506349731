"use strict";

module.exports = function () {
    return {
        restrict: "AE",
        replace: true,
        scope: {
            resident: "=",
            save: "&",
            cancel: "&",
            refreshCallback: "="
        },
        controller: "residentDetails.controller",
        templateUrl: "app/residents/directives/partials/residentDetailsEditor.html"
    };
}
