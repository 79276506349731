"use strict";
const angular = require("angular");
const moment = require("moment");
const _ = require("underscore");

module.exports = [
    "residents.service",
    "notification.service",
    "modalConfig",
    "$state",
    "dialog.service",
    "organisation.facility.service",
    "DefaultTime",
    function (residentsService, notification, modalConfig, $state, dialogService, 
              organisationFacilityService, DefaultTime) {
        var vm = this;
        angular.extend(vm, modalConfig);
        // need to set fromILU to true when click edit button for each leave history event in ILU
        // to display leave dialog fields related to ILU
        if(vm.resident.careType == 'ILU')  
        {
            vm.fromILU = true;
        }    
        if (!vm.residentLeave) {
            vm.leave = vm.resident.leave ? angular.copy(vm.resident.leave) : {};
            if(vm.resident.leave)
            {
                if(!vm.leave.leaveStartTimeLocalString) 
                    vm.leave.leaveStartTimeLocalString = DefaultTime;
              
                if(!vm.leave.leaveReturnTimeLocalString) 
                    vm.leave.leaveReturnTimeLocalString = DefaultTime;
            }
        }
        else 
        {
            vm.leave = angular.copy(vm.residentLeave);  //to avoid the impact to ILU leave history page when cancel editing
            if(!vm.leave.leaveStartTimeLocalString) 
                vm.leave.leaveStartTimeLocalString = DefaultTime;
          
            if(!vm.leave.leaveReturnTimeLocalString) 
                vm.leave.leaveReturnTimeLocalString = DefaultTime;
        }
        vm.residentFirstName = vm.resident.firstName;
        vm.residentLastName = vm.resident.lastName;
        vm.residentCareType = vm.resident.careType;
        vm.isPrivateResidency = vm.resident.isPrivateResidency;
        vm.residentId = vm.resident.id;
        vm.residentialFacilities = [];
        vm.selectedFacility;

        vm.isReturnedDialog = vm.leave.returnDateUtc != null;
        vm.leaveReturnTypeIsExpected = !vm.isReturnedDialog;

        vm.bindHide = function ($hide) {
            vm.$hide = $hide;
        };

        Object.defineProperty(vm, "minimumReturnDateUtc", {
            get: function () {
                var value = null;
                if (vm.leave.startDateUtc) {
                    value = moment(vm.leave.startDateUtc).add(1, "days").startOf("day").toDate();
                }
                return value;
            }
        });

        function getResidentialFacilities()
        {
            organisationFacilityService.getAllOrganisationFacilities(true).then(
            response => {
                vm.residentialFacilities = response.filter(x => x.facilityType == "AgeCare");
                if(vm.residentialFacilities.length >0  && vm.selectedFacility == undefined)
                {
                  vm.leave.isRespiteLeaveWithinOrganisation = true;
                }
            });
        }

        function prePopulateLeaveType() {
            if (!vm.leave.type) {
                vm.leaveType = "Social";
                vm.leaveClaimable = true;
            } else {
                if (vm.leave.type === "SocialNonClaimable") {
                    vm.leaveType = "Social";
                    vm.leaveClaimable = false;
                } else if (vm.leave.type === "TransitionNonClaimable") {
                    vm.leaveType = "TransitionCare";
                    vm.leaveClaimable = false;
                } else {
                    vm.leaveType = vm.leave.type;
                    vm.leaveClaimable = true;
                }
            }

            if (vm.fromILU) {
                vm.leaveClaimable = false;
                getResidentialFacilities();
            }
        }

        prePopulateLeaveType();

        function applyClaimableToggle() {
            if (vm.leaveClaimable === false) {
                if (vm.leave.type === "Social") {
                    vm.leave.type = "SocialNonClaimable";
                } else if (vm.leave.type === "TransitionCare") {
                    vm.leave.type = "TransitionNonClaimable";
                }
            }
        }

        function onSucess(action) {
            notification.success("Leave " + action + ".");
            vm.$hide();
            //this dialog can be called from multiple screens. Reload the page if no onModalCloseCallback has been passed.
            if (modalConfig.onModalCloseCallback != undefined) {
                modalConfig.onModalCloseCallback();
            }
            else {
                $state.reload();
            }
        }

        function onError(error, action) {
            if (!error.modelStateSummary) {
                notification.error("Unable while " + action + " leave. Either your computer is offline, or Resident Manager is having problems of its own.");
            } else {
                dialogService.openMessageDialog(
                    "Send resident leave",
                    "The following errors occurred when " + action + " the leave:",
                    "residentLeave-error",
                    _.values(error.modelStateSummary));
            }
        }

        vm.sendOnLeave = function (isleaveSubmissionFormValid) {
            if (isleaveSubmissionFormValid) {
                vm.leave.type = vm.leaveType;
                vm.leave.isPrivateResidency = vm.isPrivateResidency;
                if (!vm.fromILU)
                    applyClaimableToggle();

                if (!vm.leave.id) {
                    //submit leave
                    residentsService.sendOnLeave(vm.resident.id, vm.leave).then(function () {
                        onSucess("added");
                    }, function (error) {
                        onError(error, "adding");
                    });
                } else {
                    //update leave
                    residentsService.updateLeave(vm.leave).then(function () {
                        onSucess("updated");
                    }, function (error) {
                        onError(error, "updating");
                    });
                }
            }
        }

        vm.reverseLeave = function () {
                if(modalConfig.origin === "eventsHistory"){
                dialogService.openDialog("app/funding/partials/reverseEvent.html",
                "reverseEvent.controller",
                {
                    width: "100px",
                    selectedEvent: {
                        uniqueEventId: vm.uniqueEventId,
                        residentId: vm.resident.id,
                        requestType: vm.requestType,
                        residentLeaveMedicareEvent: {
                            residentLeaveId:vm.resident.leave.id
                        }
                    },
                    onModalCloseCallback: vm.closeSendOnLeavePopup
                });
            }
            else {
                residentsService.reverseLeave(vm.resident.id, vm.leave.id).then(function () {
                    onSucess("reversed");
                }, function (error) {
                    onError(error, "reversing");
                });
            }
        }

        vm.closeSendOnLeavePopup = function() {
            $state.reload();
            vm.$hide();
        }

        vm.resetReturnValue = function () {
            if (vm.leaveReturnTypeIsExpected === true) {
                vm.leave.returnDateUtc = null;
            } else {
                vm.leave.expectedReturnDateUtc = null;
            }
        }  

        vm.resetReturnValue();
    }
];
