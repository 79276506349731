import * as _ from "underscore";
import { ExternalSystemType } from "../reporting/Models";

enum Period {
    Daily = 1,
    Weekly = 2,
    Fortnightly = 3,
    Monthly = 4,
    Yearly = 5
}

enum OptionalServiceStatus {
    New = 1,
    Scheduled = 2,
    Stopped = 3
}

enum IluStatus 
{
    Available = "Available",
    Occupied = "Occupied",
    OutOfService = "Out of service",
    Sold = "Sold"
}

enum RoomTypePriceSetting  {
        Room = 0,
        Bed = 1
}

enum StopBillingEvent  {
    Departure = 1,
    Settlement = 2
}

class OptionalServiceRate {
    public id: number;
    public startDate: Date;
    public endDate: Date | null;
    public value: string;
    public isEditable: boolean;

    constructor(id: number, startDate: Date, endDate: Date | null, value: string) {
        this.id = id;
        this.startDate = startDate;
        this.endDate = endDate;
        this.value = value;
        this.isEditable = false;
    }
}

class OptionalService {
    public id: number;
    public typeDescription: string;
    public billingPeriodId: number;
    public billingPeriod: Period;
    public chargeType: string;
    public ratesList: Array<OptionalServiceRate>;
    public insertFlag: boolean;
    public status: OptionalServiceStatus;
    public displayStartDate: Date = null;
    public displayStopDate: Date = null;
    public stopped: boolean;
    public rate: string;
    public todayRateIndex: number;
    public stopBillingEvent?: number;
    public index: number = 0;

    private _displayRateIndex: number;
    private _displayRateId: number;

    constructor(id: number, typeDescription: string, billingPeriodId: number, chargeType: string, ratesList: Array<OptionalServiceRate>, insertFlag: boolean, stopBillingEvent: number) {
        this.id = id;
        this.typeDescription = typeDescription;
        this.billingPeriodId = billingPeriodId;
        this.billingPeriod = Period[billingPeriodId];
        this.chargeType = chargeType;
        this.ratesList = ratesList;
        this.insertFlag = insertFlag;
        this.stopBillingEvent = stopBillingEvent;

        // map each rate through the constructor so we have proper OptionalServiceRate objects
        this.setRatesList(ratesList);

        // set the status based on the input
        // this is done once here because it doens't change as the user edits the form
        if (this.insertFlag) {
            this.status = OptionalServiceStatus.New;
        } else if (parseFloat(this.ratesList[0].value) > 0 || this.ratesList[0].startDate.getTime() > (new Date()).getTime()) {
            this.status = OptionalServiceStatus.Scheduled;
        } else {            
            this.status = OptionalServiceStatus.Stopped;
        }
    }

    public static buildArray(optionalServices: any[]): Array<OptionalService> {
        var result = optionalServices.map((optionalService) => {
            return new OptionalService(
                optionalService.id,
                optionalService.typeDescription,
                optionalService.billingPeriodId ? parseFloat(optionalService.billingPeriodId) : 0,
                optionalService.chargeType,
                optionalService.ratesList,
                false,
                optionalService.stopBillingEvent
            );
        });

        // sort alphabetically
        result = _.sortBy(result, optionalService => optionalService.typeDescription.toLowerCase());

        return result;
    }

    public sortRatesList() {
        // sort with newest first
        this.ratesList = _.sortBy(this.ratesList, rate => rate.startDate ? -rate.startDate.getTime() : 0);
    }

    public setStartDate(startDate : Date) {
        this.ratesList[this.displayRateIndex].startDate = startDate;
        this.refreshDisplayProperties();
    }
    
    public setStopDate(stopDate: Date) {
        // handle no stop date
        if (stopDate == null) {
            if (this.ratesList.length) {
                // we're clearing the stop date, so if the most recent rate is 0, then we're going to delete it
                if (this.ratesList[0].value == '' || parseFloat(this.ratesList[0].value) == 0) {
                    this.ratesList.splice(0, 1);
                    // as the ratesList has changed, refresh the display properties to ensure the ui is up to date
                    this.refreshDisplayProperties();
                }
            }
            return;
        }

        // don't do anything if we're pushing the stop date to before the current date range, as this is invalid
        if (stopDate.getTime() < this.ratesList[this.displayRateIndex].startDate.getTime()) {
            return;
        }

        // if the stop date is changed, then we adjust the ratesList to make the service
        // end on the stop date

        // first, as the incoming stopDate is the LAST day the service is active, then first calculate what the 
        // new effective start date will be (the following day)
        let serviceStopEffectiveStartDate = new Date(stopDate.getTime());
        serviceStopEffectiveStartDate.setUTCDate(serviceStopEffectiveStartDate.getUTCDate() + 1);

        // now remove all date ranges that start on or after the effective start date
        // as we're removing items, walk through the array backwards
        for (let rateIndex = this.ratesList.length - 1; rateIndex >= 0; rateIndex--) {
            if (this.ratesList[rateIndex].startDate >= serviceStopEffectiveStartDate) {
                this.ratesList.splice(rateIndex, 1);
            }
        }

        // now, if the most recent rate is a zero, then we're going to delete it and re-add it, so delete it here
        if (this.ratesList.length) {
            if (parseFloat(this.ratesList[0].value) == 0) {
                this.ratesList.splice(0, 1);
            }
        }

        // set the previous rate's end date
        if (this.ratesList.length) {
            this.ratesList[0].endDate = stopDate;
        }

        // now we create a new rate entry with the service stopped
        this.ratesList.unshift(new OptionalServiceRate(null, serviceStopEffectiveStartDate, null, "0"));

        // as the ratesList has changed, let's refresh the display properties to ensure the ui is up to date
        this.refreshDisplayProperties();
    }

    public get displayRateIndex() {
        return this._displayRateIndex;
    }

    private setRatesList(ratesList: any[]) {
        this.ratesList = new Array<OptionalServiceRate>();
        if (ratesList && ratesList.length) {
            this.ratesList = ratesList.map(rate => {
                return new OptionalServiceRate(
                    rate.id,
                    rate.startDate ? new Date(rate.startDate.toString()) : null,
                    rate.endDate ? new Date(rate.endDate.toString()) : null,
                    rate.value
                );
            });
        } else {
             // no effective date provided, so create an empty one so the user can provide values
             this.ratesList.push(new OptionalServiceRate(null, null, null, ''));
        }

        this.sortRatesList();

        this.refreshDisplayProperties();
    }

    private refreshDisplayProperties() {
        // refresh the display index to display the date for "today"
        // based on the assumption that the display dates are sorted ascendingly,
        // which is how the server returns them, and how this app manages them

        // default to the oldest rate
        this._displayRateIndex = this.ratesList.length - 1;

        let find = this.ratesList.filter(rate => { return rate.id == this._displayRateId});
        if (this._displayRateId && find.length == 1) {
            // if we have a previously set display rate id and it exists, then use that as we don't want it to change it on the fly
                this._displayRateIndex = this.ratesList.indexOf(find[0]);
        } else if (!this.insertFlag) { // don't change it if we have a new optional service
            for (let i = 0; i < this.ratesList.length; i++) {
                let now = new Date();
                // only check against start date as we might not have an end date
                if (now >= this.ratesList[i].startDate) {
                    this._displayRateIndex = i;
                    // cache the id so we can persist it later if the rates list indeices change
                    if (this.ratesList[i].id) {
                        this._displayRateId = this.ratesList[i].id
                    }
                    break;
                }
            }
        }

        // set the display start date based on the current range
        this.displayStartDate = this.ratesList[this._displayRateIndex].startDate;

        // now set the display stop date if the most recent date range is zeroed out
        let recentRatesListEntry = this.ratesList[0];
        if (parseFloat(recentRatesListEntry.value) == 0) {
            var stopDate = new Date(recentRatesListEntry.startDate.getTime());
            stopDate.setUTCDate(stopDate.getUTCDate() - 1);
            this.displayStopDate = stopDate;
        } else {
            this.displayStopDate = null;
        }
    }
};

class SimpleGLAccountMapping {
    public id: string;
    public otherInfo: string;
}

class GLAccountMapping {
    public mappingType: string;
    public resmanValue: string;
    public mappingValue: string;
    public otherInfo: string;
    public modifiedByUser: User;
    public modifiedOnUtc: Date;
    public mappingGroup: string;
    public mappingGroupDescription: string; 
    public insertFlag: boolean;
}

class User {
    public firstName: string;
    public lastName: string;
    public isProdaAuthorised: boolean
}

class AccountMappingGroup {
    public groupHeader: string;
    public groupHeaderDescription: string;
    public accountMappings: Array<AccountMapping>;
    constructor(groupHeader: string, groupHeaderDescription: string, accountMappings: Array<AccountMapping>) {
        this.groupHeader = groupHeader;
        this.groupHeaderDescription = groupHeaderDescription;
        this.accountMappings = accountMappings;       
    }
}

class AccountMapping {
    public type: string;
    public description: string;
    public mapping: GLAccountMapping;
    public account: string;
    public insertFlag: boolean;
    constructor(type: string, account: string, mapping: GLAccountMapping, insertFlag: boolean ) {
        this.type = type; // Other Info
        this.account = account; // mapping Value      
        this.mapping = mapping; // actual mapping 
        this.insertFlag = insertFlag // set true when new sundryCharge Account is added
    }    
}

class ViewOrEditAccommodationDto  {
    public id: number;
    public number: string;
    public features: any;
    public facilityLocationId?: number;
    public accommodationTypeId: number;
    public beds: any;
    public iluStatus: IluStatus;
    public occupants: UnitOccupantDTO[];
    public facilityLocation: any;
}

class UnitOccupantDTO {
    public enquiryId: number;
    public residentId: number;
    public firstName: string;
    public lastName: string;
}

class AccommodationDto {
    public accommodationId: number;
    public roomNumber: string;
    public accommodationName: string;
    public beds: any;
    public price: number;
    public iluStatus: IluStatus;
    public occupants: UnitOccupantDTO[];
}

class LocationWithAccommodationsDto {
    public id?: number;
    public locationName: string;
    public accommodations: AccommodationDto[];
}

class AccommodationTypeDto {
        public id: number;
        public name: string;
        public numberBeds: number;
        public priceSetting: RoomTypePriceSetting;
        public priceSettingDescription: string;
        public price: number;
        public extraService?: number;
        public numberRoomsAttached: number;
        public features: any[];
    }

class AccommodationFeatureDto {
    public id: string;
    public name: string;
}

class AccommodationTypeFeatureDto {
    public id: string;
    public name: string;
}

class AddAccommodationDto {
    public roomNumber: string;
    public accommodationTypeId: number;
    public facilityLocationId?: number;
}

class EditAccommodationDto {
    public id: number;
    public accommodationTypeId: number;
    public number: string;
    public facilityLocationId?: number;
    public iluStatus: IluStatus;
    public features: AccommodationFeatureDto[];
}

class FacilityDto {
    public id: number
    public name: string
    public externalSystemTypes: ExternalSystemType[];
}

class EditBedStatusDto {
    public id: number;
    public bedId: number
    public startDateUtc: Date;
    public endDateUtc: Date;
    public isDeleted: boolean;
}

export { SimpleGLAccountMapping, GLAccountMapping, User, AccountMappingGroup, AccountMapping, OptionalService, OptionalServiceRate, OptionalServiceStatus, Period
    , IluStatus, ViewOrEditAccommodationDto, UnitOccupantDTO, AccommodationDto, LocationWithAccommodationsDto
    , AccommodationTypeDto, AccommodationFeatureDto, AccommodationTypeFeatureDto
    , EditAccommodationDto, AddAccommodationDto, FacilityDto, EditBedStatusDto, StopBillingEvent}
