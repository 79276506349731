"use strict";
const _ = require("underscore");

module.exports =
    [
        "$scope",
        function ($scope) {
            $scope.selectRoom = function (id) {
                $scope.selectedRoom = _.findWhere($scope.availableRooms, { id: id });
                $scope.selectedBedId = $scope.selectedRoom.beds[0].id;
            };
        }
    ];
